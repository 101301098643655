import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';

import { AppComponent } from './app.component';
import { SignupComponent } from './signup/signup.component';
import { LandingComponent } from './landing/landing.component';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ZeroComponent } from './zero/zero.component';
import { OctaflowComponent } from './one_octaflow/octaflow.component';

import { HomeModule } from './home/home.module';
import { LoginComponent } from './login/login.component';
import { FileConverterModule } from './fileConverter/fileConverter.module';
import { HttpClientModule } from '@angular/common/http';
import { MapModule } from './map/map.module';

@NgModule({
    declarations: [
        AppComponent,
        SignupComponent,
        LandingComponent,
        ProfileComponent,
        NavbarComponent,
        FooterComponent,
        LoginComponent,
        ZeroComponent,
        OctaflowComponent
    ],
    imports: [
        BrowserModule,
        NgbModule,
        FormsModule,
        RouterModule,
        AppRoutingModule,
        HomeModule,
        HttpClientModule,
        FileConverterModule,
        MapModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
