import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'one-octaflow',
    templateUrl: './octaflow.component.html',
    styleUrls: ['./octaflow.component.scss']
})

export class OctaflowComponent implements OnInit {
  focus: any;
  focus1: any;

  constructor() { }

  ngOnInit() {}

}
