import { Component, OnInit } from '@angular/core';
import { FileConverterService } from '../services/fileConverter.service';

@Component({
    selector: 'file-Converter',
    templateUrl: './fileConverter-details.html',
    styleUrls: ['./fileConverter.css'],
    providers: [FileConverterService]
})

export class FileConverterDetails implements OnInit {

    files: File[];

    constructor(
        private fileConvertService: FileConverterService,) { }

    ngOnInit() { }


    fileChange(event: any) {
        this.files = event.target.files;

        this.fileConvertService.uploadFiles(this.files).subscribe(
            response => {
                if (response.status == "Ok") {
                    for (let i = 0; i < response.data.length; i++) {
                        var folderName = JSON.stringify(response.data[i]);
                        //var obj = JSON.parse(folderName);
                        //var aaa = obj.data;
                        var link = document.createElement('a');
                        document.body.appendChild(link);
                        link.href = "FileConverter/Download?fileName=" + response.data[i];
                        link.click();
                    }
                    
                }
            },
            err => {
            
            });

    }

}
