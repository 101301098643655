import { DrawingMode } from './drawingMode';
import { GeoHelper } from './geoHelper';
import { Position } from './geometry';
import { LatLng } from './latLng';

declare var google: any;
declare var $: any;
export class GMapsHelper2 {

   static initializeMap(mapElementId, mapCenter, zoom, maxZoom) {

      if (mapCenter === null || mapCenter === "")
         mapCenter = GeoHelper.getBrowserLocation({ lat: 18.5245649, lng: 73.7228783 });

      var mapOptions = {
         zoom: zoom,
         maxZoom: maxZoom,
         center: mapCenter,
         fullscreenControl: false,
         zoomControl: true,
         zoomControlOptions: {
            position: google.maps.ControlPosition.LEFT_BOTTOM
         },
         controlSize: 30,
         mapTypeControl: false,
         scaleControl: true,
         streetViewControl: false,
         rotateControl: true,
         styles: [
            {
               "featureType": "water",
               "stylers": [
                  { "saturation": 43 },
                  { "lightness": -11 },
                  { "hue": "#0088ff" }
               ]
            },
            {
               "featureType": "road",
               "elementType": "geometry.fill",
               "stylers": [
                  { "hue": "#ff0000" },
                  { "saturation": -100 },
                  { "lightness": 99 }
               ]
            },
            {
               "featureType": "road",
               "elementType": "geometry.stroke",
               "stylers": [
                  { "color": "#808080" },
                  { "lightness": 54 }
               ]
            },
            {
               "featureType": "landscape.man_made",
               "elementType": "geometry.fill",
               "stylers": [
                  { "color": "#ece2d9" }
               ]
            },
            {
               "featureType": "poi.park",
               "elementType": "geometry.fill",
               "stylers": [
                  { "color": "#ccdca1" }
               ]
            },
            {
               "featureType": "road",
               "elementType": "labels.text.fill",
               "stylers": [
                  { "color": "#767676" }
               ]
            },
            {
               "featureType": "road",
               "elementType": "labels.text.stroke",
               "stylers": [
                  { "color": "#ffffff" }
               ]
            },
            {
               "featureType": "poi",
               "stylers": [
                  { "visibility": "off" }
               ]
            },
            {
               "featureType": "landscape.natural",
               "elementType": "geometry.fill",
               "stylers": [
                  { "visibility": "on" },
                  { "color": "#b8cb93" }
               ]
            },
            {
               "featureType": "poi.park",
               "stylers": [
                  { "visibility": "on" }
               ]
            },
            {
               "featureType": "poi.sports_complex",
               "stylers": [
                  { "visibility": "on" }
               ]
            },
            {
               "featureType": "poi.medical",
               "stylers": [
                  { "visibility": "on" }
               ]
            },
            {
               "featureType": "poi.business",
               "stylers": [
                  { "visibility": "simplified" }
               ]
            }]
      };

      // Get all html elements for map
      var mapElement = document.getElementById(mapElementId);

      // Create the Google Map using elements
      return new google.maps.Map(mapElement, mapOptions);

   }

   static initializeInventoryMap(mapElementId, mapCenter, zoom, maxZoom) {

      if (mapCenter === null || mapCenter === "")
         mapCenter = GeoHelper.getBrowserLocation({ lat: 18.5245649, lng: 73.7228783 });

      var mapOptions = {
         zoom: zoom,
         maxZoom: maxZoom,
         center: mapCenter,
         fullscreenControl: false,
         zoomControl: true,
         zoomControlOptions: {
            position: google.maps.ControlPosition.LEFT_BOTTOM
         },
         controlSize: 30,
         mapTypeControl: false,
         scaleControl: true,
         streetViewControl: false,
         rotateControl: true,
         //mapTypeControlOptions: {
         //    style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
         //    position: google.maps.ControlPosition.LEFT_BOTTOM
         //},
         styles: [
            {
               "featureType": "water",
               "stylers": [
                  { "saturation": 43 },
                  { "lightness": -11 },
                  { "hue": "#0088ff" }
               ]
            },
            {
               "featureType": "road",
               "elementType": "geometry.fill",
               "stylers": [
                  { "hue": "#ff0000" },
                  { "saturation": -100 },
                  { "lightness": 99 }
               ]
            },
            {
               "featureType": "road",
               "elementType": "geometry.stroke",
               "stylers": [
                  { "color": "#808080" },
                  { "lightness": 54 }
               ]
            },
            {
               "featureType": "landscape.man_made",
               "elementType": "geometry.fill",
               "stylers": [
                  { "color": "#ece2d9" }
               ]
            },
            {
               "featureType": "poi.park",
               "elementType": "geometry.fill",
               "stylers": [
                  { "color": "#ccdca1" }
               ]
            },
            {
               "featureType": "road",
               "elementType": "labels.text.fill",
               "stylers": [
                  { "color": "#767676" }
               ]
            },
            {
               "featureType": "road",
               "elementType": "labels.text.stroke",
               "stylers": [
                  { "color": "#ffffff" }
               ]
            },
            {
               "featureType": "poi",
               "stylers": [
                  { "visibility": "off" }
               ]
            },
            {
               "featureType": "landscape.natural",
               "elementType": "geometry.fill",
               "stylers": [
                  { "visibility": "on" },
                  { "color": "#b8cb93" }
               ]
            },
            {
               "featureType": "poi.park",
               "stylers": [
                  { "visibility": "on" }
               ]
            },
            {
               "featureType": "poi.sports_complex",
               "stylers": [
                  { "visibility": "on" }
               ]
            },
            {
               "featureType": "poi.medical",
               "stylers": [
                  { "visibility": "on" }
               ]
            },
            {
               "featureType": "poi.business",
               "stylers": [
                  { "visibility": "simplified" }
               ]
            }]
      };
      // Get all html elements for map
      let mapElement = null;
      if (mapElementId == "tab_destinationNode") {
         mapElement = $("#tab_destinationNode #inventoryMap");
      }
      if (mapElementId == "Cabling") {
         mapElement = $("#cbl_cableSettings #inventoryMap");
      }
      //document.getElementById(mapElementId);

      // Create the Google Map using elements
      return new google.maps.Map(mapElement[0], mapOptions);

   }
   static setCenter(map: any, location: LatLng, zoom?: number) {
      map.setCenter(location);

      if (zoom)
         map.setZoom(zoom);
   }
    
   static createOverlay(map) {
      var overlay = new google.maps.OverlayView();
      overlay.draw = function () { };
      overlay.setMap(map);

      return overlay;
   }

   static getLatLng(latlng: number[]) {
      return new google.maps.LatLng(latlng[0], latlng[1]);
   }

   static getLatLongFromDragPoint(overlay, pageX, pageY) {
      var dropPoint = new google.maps.Point(pageX, pageY);
      return overlay.getProjection().fromContainerPixelToLatLng(dropPoint);
   }

   static addListener(entity, clickType, callback) {
      if (entity) {
         google.maps.event.addListener(entity, clickType, function (event) {
            if (typeof callback === "function")
               callback(event);
         });
      }
   }
   static addListenerOnce(entity, clickType, callback) {
      google.maps.event.addListenerOnce(entity, clickType, function (event) {
         if (typeof callback === "function")
            callback(event);
      });
   }
   static clearListener(entity, clickType, callback) {
      if (entity) {
         google.maps.event.clearListeners(entity, clickType, function (event) {
            if (typeof callback === "function")
               callback(event);
         });
      }
   }

   static drawPoint(map: any, entity: any, icon: any, isEditable: boolean, attachMapObject: boolean) {

      var coordinates = [];

      if (entity.geometry)
         coordinates = entity.geometry.coordinates;
      else if (entity.coordinates)
         coordinates = entity.coordinates;

      if (coordinates.length == 0) return null;

      let location = GeoHelper.convertToPosition(coordinates);

      let marker = new google.maps.Marker({
         position: location,
         draggable: isEditable,
         cursor: 'pointer',
         id: entity.id,
         data: entity,
         //icon: {
         //    url: icon,
         //    ////origin: new google.maps.Point(96, 0),
         //    ////size: new google.maps.Size(96, 96),
         //    ////scaledSize: new google.maps.Size(192, 96),
         //    //anchor: new google.maps.Point(10, 10)
         //},
         icon: {
            url: icon.url,
            anchor: icon.anchor ? new google.maps.Point(icon.anchor[0], icon.anchor[1]) : null
         },
         map: map
      });

      //https://pepsized.com/customize-your-google-map-markers/

      marker.setTitle(entity.layerName);

      if (attachMapObject)
         entity['mapObject'] = marker;

      return marker;
   }

   static drawLine(map: any, entity: any, lineColor: any, strokeWeight: number, isEditable: boolean, attachMapObject: boolean) {

      let colour = "#000000";
      let lineWeight = 3;

      if (lineColor)
         colour = lineColor;

      if (strokeWeight)
         lineWeight = strokeWeight;

      let polyline = new google.maps.Polyline({
         strokeColor: colour,
         strokeOpacity: 1.0,
         strokeWeight: lineWeight,
         editable: isEditable,
         data: entity,
         id: entity.id
      });

      let path = polyline.getPath();

      polyline.setMap(map);

      if (attachMapObject)
         entity['mapObject'] = polyline;

      if (entity.geometry.coordinates.length > 1) {
         for (var i = 0; i < entity.geometry.coordinates.length; i++) {
            path.push(new google.maps.LatLng(entity.geometry.coordinates[i][1], entity.geometry.coordinates[i][0]));
         }
         polyline.setEditable(false);
      }

      GMapsHelper2.addListener(polyline, 'mouseover', function (latlng) {
         polyline.setOptions({ strokeColor: '#FF0000', strokeWeight: lineWeight + 2 });
      });

      GMapsHelper2.addListener(polyline, 'mouseout', function (latlng) {
         polyline.setOptions({ strokeColor: lineColor, strokeWeight: lineWeight });
      });

      return polyline;
   }

   static drawPolygon(map: any, entity: any, lineColor: string, strokeWeight: number, isEditable: boolean, attachMapObject: boolean) {

      let colour = "#000000";
      let lineWeight = 3;

      if (lineColor)
         colour = lineColor;

      if (strokeWeight)
         lineWeight = strokeWeight;

      let polygon = new google.maps.Polygon({
         strokeColor: colour,
         strokeOpacity: 1.0,
         strokeWeight: lineWeight,
         editable: isEditable,
         data: entity,
         id: entity.id
      });

      let path = polygon.getPath();

      polygon.setMap(map);

      if (attachMapObject)
         entity['mapObject'] = polygon;

      if (entity.geometry.coordinates.length > 1) {

         for (var i = 0; i < entity.geometry.coordinates.length; i++) {
            path.push(new google.maps.LatLng(entity.geometry.coordinates[i][1], entity.geometry.coordinates[i][0]));
         }

         polygon.setEditable(false);
      }

      return polygon;
   }

   static removeMapObjects(entities: any) {

      if (entities) {

         if (Array.isArray(entities)) {
            entities.forEach(x => {
               if (x['mapObject']) {
                  x['mapObject'].setMap(null);
               }
            });
         }
         else {
            if (entities['mapObject']) {
               entities['mapObject'].setMap(null);
            }
         }
      }
   }
  
   

   static addPlaceSearch(map: any, textboxId: string, zoom: number = 15) {

      let autocomplete = new google.maps.places.Autocomplete(document.getElementById(textboxId));

      autocomplete.addListener('place_changed', () => {

         let place = autocomplete.getPlace();
         if (place.geometry) {
            map.panTo(place.geometry.location);
            map.setZoom(zoom);
         }
      });
   }

   static createrAnimationMarker(map: any, icon: string, location: LatLng) {

      let bounceMarker = new google.maps.Marker({
         map: map,
         icon: icon,
         position: location
      });

      bounceMarker.setAnimation(google.maps.Animation.BOUNCE);

      return bounceMarker;
   }

   static setMapType(map, mapType) {

      if (mapType == 'roadmap') {
         map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
      }
      else if (mapType == 'satelite') {
         map.setMapTypeId(google.maps.MapTypeId.SATELLITE);
      }
      else if (mapType == 'hybrid') {
         map.setMapTypeId(google.maps.MapTypeId.HYBRID);
      }

   }

   static getPolyline(map) {

      let colour = "#000000";
      let lineWeight = 3;

      return new google.maps.Polyline({
         strokeColor: colour,
         strokeOpacity: 1.0,
         strokeWeight: lineWeight,
         map: map
      });


   }

   static clearClickListener(entity, clickType) {
      new google.maps.event.clearListeners(entity, clickType);
   }
   static latlngbounds() {
      return new google.maps.LatLngBounds();
   }
   static setBounds(latlngs: any, map: any) {

      var bounds = new google.maps.LatLngBounds();
      for (var i = 0; i < latlngs.length; i++) {
         bounds.extend(latlngs[i]);
      }

      map.fitBounds(bounds);
   }
   static searchBox(searchText: string) {
      return new google.maps.places.SearchBox(searchText);
   }
   static drawCircleAroundPoint(coordinate: string, radius: any, color: any, _map: any) {
      var circle = new google.maps.Circle({
         strokeColor: color,
         strokeOpacity: 0.8,
         strokeWeight: 2,
         fillColor: color,
         fillOpacity: 0.35,
         center: coordinate,
         map: _map,
         radius: radius
      });
      return circle;
   }
   static drawingManagerTool(map, strDrawingMode, drawingControlModes, drawingOption) {

      var drawingManager = new google.maps.drawing.DrawingManager();

      var drawingMode = GMapsHelper2.getDrawingMode(strDrawingMode);

      drawingManager.setOptions({
         drawingMode: drawingMode,
         drawingControl: true,
         drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_LEFT,
            drawingModes: drawingControlModes
         },
         options: drawingOption
      });

      drawingManager.setMap(map);

      return drawingManager;
   }

   static getDrawingMode(drawingMode: DrawingMode) {

      switch (drawingMode) {
         case DrawingMode.POLYGON:
            return google.maps.drawing.OverlayType.POLYGON;
         case DrawingMode.CIRCLE:
            return google.maps.drawing.OverlayType.CIRCLE;
         case DrawingMode.POLYLINE:
            return google.maps.drawing.OverlayType.POLYLINE;
         case DrawingMode.RECTANGLE:
            return google.maps.drawing.OverlayType.RECTANGLE;
         case DrawingMode.MARKER:
            return google.maps.drawing.OverlayType.MARKER;
         default:
      }
   }

   static boundsToPolygon(bounds: any): Array<Array<Position>> {

      let northEastLatLngs = [];
      let southWestLatLngs = [];
      let northWestLatLngs = [];
      let southEastLatLngs = [];

      northEastLatLngs[0] = bounds.getNorthEast().lng();
      northEastLatLngs[1] = bounds.getNorthEast().lat();

      northWestLatLngs[0] = bounds.getSouthWest().lng();
      northWestLatLngs[1] = bounds.getNorthEast().lat();

      southWestLatLngs[0] = bounds.getSouthWest().lng();
      southWestLatLngs[1] = bounds.getSouthWest().lat();

      southEastLatLngs[0] = bounds.getNorthEast().lng();
      southEastLatLngs[1] = bounds.getSouthWest().lat();

      var tempCoordinates = [];
      tempCoordinates.push(northEastLatLngs);
      tempCoordinates.push(northWestLatLngs);
      tempCoordinates.push(southWestLatLngs);
      tempCoordinates.push(southEastLatLngs);
      tempCoordinates.push(northEastLatLngs);

      return [tempCoordinates];

   }

   static pathArrayToPolygon(pathArray: any): Array<Array<Position>> {

      var tempCoordinates = [];

      pathArray.forEach(x => { tempCoordinates.push([x.lng(), x.lat()]); });

      tempCoordinates.push([pathArray[0].lng(), pathArray[0].lat()]);

      return [tempCoordinates];
   }

  
}
