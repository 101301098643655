import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceResponse } from '../../common/models/serviceResponse';

@Injectable()
export class FileConverterService {

    uploadFileApi = 'FileConverter/ConvertFile';

    constructor(private http: HttpClient) {}


    uploadFiles(attachments: File[]): Observable<ServiceResponse> {

        let formData: FormData = new FormData();

        for (let i = 0; i < attachments.length; i++) {
            formData.append("Files", attachments[i], attachments[i].name);
        }
        formData.append("Folder", "");
        formData.append("Tags", "Covert");

        return this.http.post<ServiceResponse>(this.uploadFileApi, formData, { headers: { 'Accept': 'application/json' } });
    }
}
