import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MapService } from './services/mapService';
import { MapDetails } from './views/mapDetails';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
    ],
    declarations: [
        MapDetails
    ],
    providers: [
        MapService,
    ]
})
export class MapModule { }
