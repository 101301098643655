import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceResponse } from '../../common/models/serviceResponse';
import { Points, Polygons, PolyLines } from "../models/map";

@Injectable()
export class MapService {

    pointApi = 'MapDetails/ExportPoints';
    pointCSVApi = 'MapDetails/ExportPointsToCSV';
    lineCSVApi = 'MapDetails/ExportLinesToCSV';
    polygonCSVApi = 'MapDetails/ExportPolygonsToCSV';

    constructor(private http: HttpClient) { }



    ExportPoints(points: Points[], polyLines: PolyLines[], polygons: Polygons[]): Observable<ServiceResponse> {

        const params = new HttpParams()
            .set('points', JSON.stringify(points))
            .set('lines', JSON.stringify(polyLines))
            .set('polygons', JSON.stringify(polygons));

        return this.http.get<ServiceResponse>(this.pointApi, { params });
    }
    ExportPointsToCSV(points: Points[]): Observable<ServiceResponse> {

        const params = new HttpParams()
            .set('points', JSON.stringify(points));

        return this.http.get<ServiceResponse>(this.pointCSVApi, { params });
    }
    ExportLinesToCSV(polyLines: PolyLines[]): Observable<ServiceResponse> {

        const params = new HttpParams()
            .set('polyLines', JSON.stringify(polyLines));

        return this.http.get<ServiceResponse>(this.lineCSVApi, { params });
    }
    ExportpolygonsToCSV(polygons: Polygons[]): Observable<ServiceResponse> {

        const params = new HttpParams()
            .set('polygons', JSON.stringify(polygons));

        return this.http.get<ServiceResponse>(this.polygonCSVApi, { params });
    }
}
