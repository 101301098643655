import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FileConverterService } from './services/fileConverter.service';
import { FileConverterDetails } from './views/fileConverter-details';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
    ],
    declarations: [
        FileConverterDetails
    ],
    providers: [
        FileConverterService,
    ]
})
export class FileConverterModule { }
