import { LatLng } from './latLng';
import { Position, Polygon } from './geometry';
import { GeoNode } from './geoNode';

export class GeoHelper {
   static validateCoordinates(coordinates: LatLng): Error {
      const error: string[] = [];
      if (coordinates.lat > 90) { error.push('Your latitude is greater than 90°'); }
      if (coordinates.lat < -90) { error.push('Your latitude is less than -90°'); }
      if (coordinates.lng > 180) { error.push('Your longitude is greater than 180°'); }
      if (coordinates.lng < -180) { error.push('Your longitude is less than -180°'); }
      if (error.length !== 0) { return new Error(error.join(' ')); }
   }

   static convertToPosition(lngLat: number[]): LatLng {

      if (lngLat == null) return null;

      return { lng: lngLat[0], lat: lngLat[1] };
   }

   static getBrowserLocation(defaultLatLng: LatLng) {

      var options = { enableHighAccuracy: true, maximumAge: 75000 };

      if (navigator.geolocation) {
         navigator.geolocation.getCurrentPosition((position) => {
            return { lat: position.coords.latitude, lng: position.coords.longitude };
         });
      } else {
         return defaultLatLng;
      }

      return defaultLatLng;
   }


}
