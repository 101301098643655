import { LineString, Point, Polygon } from "../../common/components/geometry";

export class Points  {
    code: string;
    name: string;
    description: string;
    geometry: Point;
}
export class PolyLines {
    code: string;
    name: string;
    description: string;
    geometry: LineString;
}
export class Polygons {
    code: string;
    name: string;
    description: string;
    geometry: Polygon;
}
