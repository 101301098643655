import { Component, OnInit } from '@angular/core';
import { LineString, Point, Polygon, Position } from '../../common/components/geometry';
import { GMapsHelper2 } from '../../common/components/googleMapsHelper2';
import { Points, Polygons, PolyLines } from '../models/map';
import { MapService } from '../services/mapService';

declare var google: any;

@Component({
    selector: 'map-details',
    templateUrl: './mapDetails.html',
    providers: [MapService]
})

export class MapDetails implements OnInit {

    files: File[];
    mapHelper: any;
    map: any;
    position = [];
    points: Points[];
    filename: string;
    polyLines: PolyLines[];
    polyLine = new PolyLines();
    lineString = new LineString();
    polygons: Polygons[];
    singlepolygon: Polygons;
    coordinates = [];


    constructor(
        private mapService: MapService,) {
        this.mapHelper = GMapsHelper2;
    }

    ngOnInit() {
        let location = this.mapHelper.getLatLng([3.140853, 101.693207]);
        this.map = this.mapHelper.initializeMap('displayMap', location, 14, 21);

        this.points = new Array<Points>();
        this.polyLines = new Array<PolyLines>();
        this.polygons = new Array<Polygons>();
    }

    createMarker() {
        google.maps.event.clearListeners(this.map, "click");

        this.map.addListener("click", (event) => {
            var point = new Point();
            point.coordinates = [event.latLng.lng(), event.latLng.lat()];
            var points = new Points();
            points.code = "test";
            points.name = "test";
            points.description = "test";
            points.geometry = point;
            this.points.push(points);
            var location = this.convertToLocation(event.latLng);
            let marker = new google.maps.Marker({
                position: location,
                draggable: true,
                cursor: 'pointer',
                map: this.map
            });
            return marker;
        });
    }

    drawPolyLine() {
        this.coordinates = [];
        this.lineString = new LineString();
        this.lineString.coordinates = new Array<Position>();
        this.polyLine = new PolyLines();

        this.polyLine.code = "test";
        this.polyLine.name = "test";
        this.polyLine.description = "test";
        this.polyLine.geometry = this.lineString;
        this.polyLines.push(this.polyLine);

        google.maps.event.clearListeners(this.map, "click");
        this.drawLine();
    }

    drawLine() {
        let colour = "#000000";
        let polyline = new google.maps.Polyline({
            strokeOpacity: 1.0,
            editable: false,
            strokeColor: colour,
        });
        let path = polyline.getPath();
        polyline.setMap(this.map);

        this.map.addListener("click", (event) => {
            this.polyLines[this.polyLines.length - 1].geometry.coordinates.push([event.latLng.lng(), event.latLng.lat()]);
            polyline.setEditable(true);
            path = polyline.getPath();
            path.push(event.latLng);
        });
        return polyline;
    }

    drawPolygon() {
        this.singlepolygon = new Polygons();
        this.coordinates = [];
        var polygonCoordinate = [];
        polygonCoordinate.push(this.coordinates);
        this.singlepolygon.geometry = new Polygon();
        this.singlepolygon.geometry.coordinates = new Array<Array<Position>>();

        this.singlepolygon.code = "test";
        this.singlepolygon.name = "test";
        this.singlepolygon.description = "test";
        this.singlepolygon.geometry.coordinates = polygonCoordinate;
        this.polygons.push(this.singlepolygon);

        google.maps.event.clearListeners(this.map, "click");
        this.drawPolygon1();
    }

    drawPolygon1() {

        let colour = "#000000";
        let lineWeight = 3;
        let polygon = new google.maps.Polygon({
            strokeColor: colour,
            strokeOpacity: 1.0,
            strokeWeight: lineWeight,
            editable: false,
        });

        let path = polygon.getPath();
        polygon.setMap(this.map);

        this.map.addListener("click", (event) => {
            this.polygons[this.polygons.length - 1].geometry.coordinates[0].push([event.latLng.lng(), event.latLng.lat()]);
            polygon.setEditable(true);
            path = polygon.getPath();
            path.push(event.latLng);
        });
        return polygon;
    }

    save() {

        this.mapService.ExportPoints(this.points, this.polyLines, this.polygons).subscribe(
            response => {
                if (response.status == "Ok") {
                    for (let i = 0; i < response.data.length; i++) {
                        var folderName = JSON.stringify(response.data[i]);
                        //var obj = JSON.parse(folderName);
                        //var aaa = obj.data;
                        var link = document.createElement('a');
                        document.body.appendChild(link);
                        link.href = "FileConverter/Download?fileName=" + response.data[i];
                        link.click();
                    }
                }
            },
            err => {

            });

        if (this.points.length) {
            this.mapService.ExportPointsToCSV(this.points).subscribe(
                response => {
                    if (response.status == "Ok") {
                        var csvData = atob(response.data.fileContents);
                        var blob = new Blob([csvData], { type: 'text/csv' });
                        var url = window.URL.createObjectURL(blob);

                        if (navigator.msSaveOrOpenBlob) {
                            navigator.msSaveBlob(blob, this.filename);
                        } else {
                            var a = document.createElement("a");
                            a.href = url;
                            a.download = "Point" + "_Report" + '.csv';
                            document.body.appendChild(a);
                            a.click();
                            document.body.removeChild(a);
                        }
                        window.URL.revokeObjectURL(url);

                    }
                },
                err => {

                });
        }

        if (this.polyLines.length > 0) {
            this.mapService.ExportLinesToCSV(this.polyLines).subscribe(
                response => {
                    if (response.status == "Ok") {

                        var csvData = atob(response.data.fileContents);
                        var blob = new Blob([csvData], { type: 'text/csv' });
                        var url = window.URL.createObjectURL(blob);

                        if (navigator.msSaveOrOpenBlob) {
                            navigator.msSaveBlob(blob, this.filename);
                        } else {
                            var a = document.createElement("a");
                            a.href = url;
                            a.download = "polyline" + "_Report" + '.csv';
                            document.body.appendChild(a);
                            a.click();
                            document.body.removeChild(a);
                        }
                        window.URL.revokeObjectURL(url);

                    }
                },
                err => {

                });
        }

        if (this.polygons.length > 0) {
            this.mapService.ExportpolygonsToCSV(this.polygons).subscribe(
                response => {
                    if (response.status == "Ok") {

                        var csvData = atob(response.data.fileContents);
                        var blob = new Blob([csvData], { type: 'text/csv' });
                        var url = window.URL.createObjectURL(blob);

                        if (navigator.msSaveOrOpenBlob) {
                            navigator.msSaveBlob(blob, this.filename);
                        } else {
                            var a = document.createElement("a");
                            a.href = url;
                            a.download = "polygon" + "_Report" + '.csv';
                            document.body.appendChild(a);
                            a.click();
                            document.body.removeChild(a);
                        }
                        window.URL.revokeObjectURL(url);

                    }
                },
                err => {

                });
        }

    }

    convertToLocation(lngLat) {
        return { lng: lngLat.lat(), lat: lngLat.lng() };
    }
}
